






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import truncate from 'vue-truncate-collapsed';
import { Component, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import Multiselect from 'vue-multiselect';
import { Debounce } from 'vue-debounce-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import { getComponent, getConfigEnv } from '@/utils/helpers';
import { DateTime } from 'luxon';
import { getNameByEmail } from '@/utils/users';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import workflowModule from '@/store/modules/workflowModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import {
  CHART_CLICK_EVENT, ALL_TASQS_LIST_ITEM, SHOW_ALERT, TASQ_OFF_TARGET_TYPE,
} from '@/lib/constants';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';

@Component({
  components: {
    truncate,
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    SetpointFeedbackInfoV2: () => getComponent('tasqs/SetpointFeedbackInfoV2'),
    DefermentLabeling: () => getComponent('tasqs/DefermentLabeling'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    LineChart: () => import('@/lib/charts/lineChart'),
    Multiselect,
  },
})
export default class TasqFeedback extends mixins(DataLoading) {


  selectedSymptom(symptom) {
	  for (var x = 0; x < this.symptomOptionsSelected.length; x++) {
		//   if (symptom.name == this.symptomOptionsSelected[x].name) {
			//   this.symptomOptionsSelected[x].selected = !this.symptomOptionsSelected[x].selected
		//   }
	  }
  }


  symptomOptionsSelected = [
			  {
				title: "Electrical Fault",
				symptoms: [
					{
						name: "Engine Sensor / Control",
						selected: false
					},{
						name: "Engine Wiring",
						selected: false
					},{
						name: "Low System Voltage",
						selected: false
					},{
						name: "PLC Sensor / Control",
						selected: false
					},{
						name: "PLC Wiring",
						selected: false
					}
				]
			  },
			  {
				title: "Engine",
				symptoms: [
					{
						name: "Aux Water Level - Low",
						selected: false
					},{
						name: "Coolant Temp - High",
						selected: false
					},{
						name: "Crankcase Pressure - High",
						selected: false
					},{
						name: "Detonation",
						selected: false
					},{
						name: "Exhaust Temp - High",
						selected: false
					},{
						name: "Inlet Air Temp - High",
						selected: false
					},{
						name: "Intake Manifold Pressure - High",
						selected: false
					},{
						name: "Intake Manifold Pressure - Low",
						selected: false
					},{
						name: "Jacket Water Level - Low",
						selected: false
					},{
						name: "Jacket Water Pressure - High",
						selected: false
					},{
						name: "Jacket Water Pressure - Low",
						selected: false
					}
				]
			  }
	]


  feedbackSteps = [
    {
      id: 1,
      number: 1,
      name: 'Validate',
      active: true,
    },
    {
      id: 2,
      number: 2,
      name: 'System',
      active: false,
    },
    {
      id: 3,
      number: 3,
      name: 'Symptom',
      active: false,
    },
    {
      id: 4,
      number: 4,
      name: 'Cause',
      active: false,
    },
    {
      id: 5,
      number: 5,
      name: 'Action',
      active: false,
    },
    // {
    //   id: 4,
    //   number: 4,
    //   name: 'Resolution',
    //   active: false,
    // },
  ];

  dataInputMapping = [

    {
      label: [
        'Plunger fast arrivals',
        'Plunger non arrival',
        'Plunger slow arrivals',
        'Plunger unoptimized- loaded up well',
      ],
      action: [
        'Adjust hold LP setpoint',
        'Adjust non arrival setpoint',
        'Lower afterflow time',
        'Lower close time',
        'Lower open time',
        'Raise afterflow time',
        'Raise close time',
        'Raise open time',
        'Reassign to automation',
        'Reassign to construction',
        'Reassign to engineering',
        'Reassign to maintenance',
        'Schedule slickine',
        'Unload well with PBGL',

      ],
    },

    {
      label: [
        'Plunger worn',
      ],

      action: [
        'Reassign to engineering',
        'Reassign to maintenance',
        'Replace with better sealing plunger',
        'Replace with faster falling plunger',
        'Replace with same kind plunger',

      ],
    },

    //
    {
      label: [
        'Plunger failure',
      ],

      action: [
        'Schedule slickine',
      ],
    },

    //
    {
      label: [
        'Valve frozen',
        'Valve leaking',
        'Valve seat/seal problem',
        'Valve stuck/hung',
      ],

      action: [
        'Chemical adjustments',
        'Reassign to construction',
        'Reassign to maintenance',
        'Reinstall valve',
        'Replace valve components',
        'Thaw valve',

      ],
    },

    //
    {
      label: [
        'Comp. Recip. compressor Will Not Start',
        'Comp. Recip. crankshaft Oil Seal Leaks',
        'Comp. Recip. excessive Carbon on Valves',
        'Comp. Recip. excessive Packing Leakage',
        'Comp. Recip. frame Knocks',
        'Comp. Recip. high Discharge Temperature',
        'Comp. Recip. low Oil Pressure',
        'Comp. Recip. motor Will Not Synchronize',
        'Comp. Recip. noise in Cylinder',
        'Comp. Recip. packing Overheating',
        'Comp. Recip. piston Rod Oil Scraper Leaks',
        'Comp. Recip. relief Valve Popping',
        'Comp. Recip. low suction pressure',
        'Comp. Recip. high discharge pressure',

      ],

      action: [

        'Adjust lube supply',
        'Adjust piston rod for proper end clearance',
        "Adjust RPM's",
        'Adjust suction pressure',
        'Clean coolant passages/install water filter/increase supply pressure',
        'Clean intercooler/piping Reduce lube rates',
        'Clean jackets',
        'Clean piping/gas supply',
        'Clean strainer',
        'Clean/replace oil filter cartridge',
        'Correct voltage or power supply',
        'Increase oil pressure, repair leaks',
        'Reassign to automation',
        'Reassign to construction',
        'Reassign to engineering',
        'Reassign to maintenance',
        'Reduce coolant inlet temperature',
        'Reduce pressure and increase at more gradual rate',
        'Relieve obstruction',
        'Repair or replace valve',
        'Repair valves/rings',
        'Repair/replace defective parts',
        'Repair/replace parts',
        'Replace gasket and reassemble properly',
        'Replace gaskets',
        'Replace lubricator check valve/lubricator pumping unit',
        'Replace packing rings',
        'Reset valve',
        'Set shim tabs and bearing clearances',
        'Tighten set screws',
        'Tighten/replace loose parts',
        'Use correct lube oil and correct lube rate',

      ],
    },

    //
    {
      label: [
        'Comp. Screw Discharge gas temp. High',
        'Comp. Screw Excessive noise, vibration or periodic knocking',
        'Comp. Screw Jacket outlet water temperature excessive',
        'Comp. Screw Low discharge pressure',
        'Comp. Screw Low or no capacity',
        'Comp. Screw low oil pressure',
        'Comp. Screw low oil pressure',
        'Comp. Screw low suction pressure',
        'Comp. Screw high discharge pressure',

      ],

      action: [
        'Adjust oil level, oil filter and oil pressure',
        'Adjust relief valve setting',
        'Adjust RPM',
        'Adjust suction pressure',
        'Clean inlet filter',
        'Clean strainer',
        'Clean suction line',
        'Clean valve and replace any worn or broken parts',
        'Clean water jacket and filter or treat water supply as required',
        'Clean, repair or replace regulating valve',
        'Increase water flow rate or provide coolwater supply',
        'Load compressor',
        'Open suction valve',
        'Readjust throttle valve control',
        'Reassign to automation',
        'Reassign to construction',
        'Reassign to engineering',
        'Reassign to maintenance',
        'Reduce suction temperature',
        'Remove restriction',
        'Repair all leaks',
        'Repair valve or controls',
        'Support piping',
        'Tighten bolts',

      ],
    },

    //
    {
      label: [
        'Alarm shutdown LEL',
        'Alarm shutdown O2',
        'Alarm shutdown other',
        'Alarm shutdown ounces ECD',
        'Alarm shutdown red eye',

      ],

      action: [
        'Clear alarm',
        'Reassign to automation',

      ],
    },

    //
    {
      label: [
        'Winter batteries dead',
        'Winter damaged insulation',
        'Winter low/no chemical',
        'Winter methanol pump failure',
        'Winter separator burner out',
        'Winter solar panels not charging battery',
        'Winter freeze',

      ],

      action: [
        'Adjust solar panel direction',
        'Break freeze',
        'Clear snow from solar panel',
        'Increase chemical injection rate',
        'Reassign to automation',
        'Reassign to construction',
        'Reassign to engineering',
        'Reassign to maintenance',
        'Refill chemical ',
        'Relight burner',
        'Repair insulation',
        'Repair pump',
        'Repair/replace burner',
        'Repair/replace solar panel',
        'Replace batteries',

      ],
    },

    //
    {
      label: [
        'Measurement meter calibration',
        'Measurement multivariate calibration',
        'Measurement pressure sensor failure',

      ],

      action: [
        'Reassign to automation',
        'Reassign to maintenance',
        'Recalibrate meter',
        'Recalibrate multivariate',
        'Repair/replace meter',
        'Repair/replace multivariate',

      ],
    },

    //
    {
      label: [
        'Chemical leaking',
        'Chemical high injection rates',
        'Chemical low',
        'Chemical low injection rates',
        'Chemical out',
      ],

      action: [
        'Increase chemical injection rate',
        'Reassign to automation',
        'Reassign to construction',
        'Reassign to maintenance',
        'Reduce chemical injection rate',
        'Refill chemical',
        'Repair/replace pump components',

      ],
    },

    //
    {
      label: [
        'Auto. batteries dead',
        'Auto. power failure',
        'Auto. RTU failure',
        'Auto. solar panels not charging battery',
        'Auto. comms failure',
      ],

      action: [
        'Adjust solar panel direction',
        'Reassign to automation',
        'Reassign to construction',
        'Reassign to maintenance',
        'Repair power supply',
        'Repair/replace RTU',
        'Replace batteries',
        'Restart RTU',

      ],
    },

    //
    {
      label: [
        'GL excessive gas usage',
        'GL fluctuating gas lift line pressure',
        'GL frozen valve',
        'GL high back pressure',
        'GL high casing pressure',
        'GL high separator pressure',
        'GL injection gas choke freezing',
        'GL low casing pressure',
        'GL low gas usage',
        'GL restriction',
        'GL valve hung open',
        'GL valve throttling',
        'GL well blowing dry gas',
        'GL well flowing in heads',
        'GL well will not accept input gas',
        'GL well will not unload',

      ],

      action: [
        'Casing pressure build with quick open cycle',
        'Hot oil flowline',
        'hot water flush',
        'Increase choke size',
        'Increase injection pressure',
        'Increase the system gas pressure.',
        'Increasing the gas input choke',
        'Install a slightly larger input choke may reduce freezing',
        'Open gas lift line valve',
        'Open valve',
        'Reassign to automation',
        'Reassign to construction',
        'Reassign to maintenance',
        'Reduce choke size',
        'Reduce injection pressure',
        'Remove flowline choke/restriction',
        'Remove obstruction',
        'Rock the well',
        'Thaw choke',
        'Unthaw choke',
        'Upsize orifice',

      ],
    },

    //
    {
      label: [
        'Separator burner out',
        'Separator level high',
        'Separator level low',
        'Separator oil in water tanks',
        'Separator water carry over',

      ],

      action: [
        'Adjust level controller',
        'Reassign to automation',
        'Reassign to construction',
        'Reassign to maintenance',
        'Relight burner',
        'Repair/replace level controller',

      ],
    },

    //
    {
      label: [
        'Choke frozen',
        'Choke increase',
        'Choke leaking',
        'Choke reduction',
        'Choke restriction',

      ],

      action: [
        'Clear restriction',
        'Decrease choke size',
        'Increase choke size',
        'Reassign to construction',
        'Reassign to engineering',
        'Reassign to maintenance',
        'Repair/replace choke',
        'Thaw choke',

      ],
    },

    //
    {
      label: [
        'Piping frozen',
        'Piping leaking',
        'Piping restriction',

      ],

      action: [
        'Reassign to construction',
        'Reassign to maintenance',
        'Remove restriction',
        'Repair leaking piping',
        'Thaw piping',

      ],
    },

    //
    {
      label: [
        'Line pressure high',

      ],

      action: [
        'Adjust hold LP setpoint',
        'Increase backpressure',
        'No action needed',
        'Reduce backpressure',

      ],
    },

    //
    {
      label: [
        'Planned activity',

      ],

      action: [
        'No action needed',

      ],
    },

    //
    {
      label: [
        'Pump excessive noise and vibration',
        'Pump excessive power consumption',
        'Pump failure',
        'Pump no or low flow',
        'Pump no or low pressure',
        'Pump seal leakage',

      ],

      action: [
        'Add orifice',
        'Correct power issue',
        'Fix flow switch',
        'Increase fluid temperature',
        'Increase pump speed',
        'Reassign to automation',
        'Reassign to construction',
        'Reassign to maintenance',
        'Reduce cavitation ',
        'Reduce discharge pressure',
        'Remove clog',
        'Remove gas from suction line',
        'Remove obstruction',
        'Repair leak',
        'Repair leaks',
        'Replace seal',
        'Reverse direction',
        'Unclog filter/strainer',

      ],
    },

    //
    {
      label: [

        'Wellhead failure',
      ],

      action: [
        'Reassign to construction',
        'Reassign to engineering',
        'Reassign to maintenance',
        'Repair wellhead',
        'Replace wellhead',

      ],
    },

    //
    {
      label: [
        'VFD failure',

      ],

      action: [
        'Adjust VFD setting',
        'Reassign to automation',
        'Reassign to construction',
        'Reassign to maintenance',
        'Repair power supply',

      ],
    },

  ];

  actionMappingValues: any = [];

  windowWidth = window.innerWidth;

  get isOnMobile() {
	  return this.windowWidth < 769
  }


  get labelsMapping() {
    const labelMap = this.dataInputMapping.map((input) => input.label);
    const result = Array.prototype.concat(...labelMap);
    result.push('Other');
    return result.sort((a, b) => a.localeCompare(b));
  }

  actionMapping() {
    const actionMap = this.dataInputMapping.map((input) => {
      const labelActions: any = [];
      input.label.forEach((labelValue) => {
        // @ts-ignore
        if (this.reason.includes(labelValue)) {
          labelActions.push(input.action);
        }
      });
      return Array.prototype.concat(...labelActions);
    });
     let result  = [...new Set(Array.prototype.concat(...actionMap))]
     result.push('Other')
    this.actionMappingValues = result.sort((a, b) => a.localeCompare(b));
  }

  @Watch('reason')
  updateReasonValue(value) {
    // @ts-ignore
    if (value.find((j) => j.includes('Other'))) {
      // @ts-ignore
      this.showComment = true;
    }
    this.actionMapping();
  }

  get setpointNameRowData() {
	  const results: any[] = [];
	  if (this.setpointDetailsWellHistory == null) {
		  return results;
	  }
	  const mapSetpointNames = {
		  open_to_close: 'Flow timer close',
		  close_flowrate: 'Flowrate close',
		  flowrate_time_limit: 'Flowrate low time limit',
		  evaluation_time_limit: 'Evaluation time limit',
		  open_lift_pressure: 'Case-Line Open',
		  close_to_open: 'Closed timer',
		  'Open Timer': 'Flow timer close',
		  'Close time': 'Closed timer',
		  'Close Time': 'Closed timer',
		  'Closed timer': 'Closed timer',
		  'Plunger Fall Delay': 'Hold plunger fall timer',
	  };

	  results.push('Afterflow timer close');
	  results.push('Flowrate low time limit');
	  results.push('Closed timer');
	  for (const [key, value] of Object.entries(this.setpointDetailsWellHistory!['Current Setpoints'])) {
      if (mapSetpointNames[key] != null) {
        results.push(mapSetpointNames[key]);
      }
	  }
	  return results;
  }

  get setpointEstimatedCurrentRowData() {
	  const results: any[] = [];
	  if (this.setpointDetailsWellHistory == null) {
		  return results;
	  }
	  const mapSetpointNames = {
		  open_to_close: 'Flow timer close',
		  close_flowrate: 'Flowrate close',
		  flowrate_time_limit: 'Flowrate low time limit',
		  evaluation_time_limit: 'Evaluation time limit',
		  open_lift_pressure: 'Case-Line Open',
		  close_to_open: 'Closed timer',
		  'Open Timer': 'Flow timer close',
		  'Close time': 'Closed timer',
		  'Close Time': 'Closed timer',
		  'Closed timer': 'Closed timer',
		  'Plunger Fall Delay': 'Hold plunger fall timer',
	  };

	  results.push('-');
	  results.push('-');
	  results.push('-');
	  for (const [key, value] of Object.entries(this.setpointDetailsWellHistory!['Current Setpoints'])) {
      if (mapSetpointNames[key] != null) {
        // @ts-ignore
        results.push(parseFloat(value).toFixed(2));
      }
	  }
	  return results;
  }

  get setpointRecommendedRowData() {
	  const results: any[] = [];
	  if (this.setpointDetailsWellHistory == null) {
		  return results;
	  }
	  const mapSetpointNames = {
		  open_to_close: 'Flow timer close',
		  close_flowrate: 'Flowrate close',
		  flowrate_time_limit: 'Flowrate low time limit',
		  evaluation_time_limit: 'Evaluation time limit',
		  open_lift_pressure: 'Case-Line Open',
		  close_to_open: 'Closed timer',
		  'Open Timer': 'Flow timer close',
		  'Close time': 'Closed timer',
		  'Close Time': 'Closed timer',
		  'Closed timer': 'Closed timer',
		  'Plunger Fall Delay': 'Hold plunger fall timer',
	  };
	  results.push('Disable');
	  results.push('0.5');
	  results.push('Disabled');
	  for (const [key, value] of Object.entries(this.setpointDetailsWellHistory!['Optimal Setpoints'])) {
      if (mapSetpointNames[key] != null) {
        // @ts-ignore
        results.push(parseFloat(value).toFixed(2));
      }
	  }
	  return results;
  }



  closeBatchResponseFeedbackSidebar() {
	  this.$emit('close-editing');
  }

  showWellHistorySetpointDetails(data) {
	  this.showSetpointWellHistoryDetailsPopup = true;
	  this.setpointDetailsWellHistory = data;
  }

  closeSetpointWellHistoryModal() {
	  this.showSetpointWellHistoryDetailsPopup = false;
	  this.setpointDetailsWellHistory = null;
  }

  setpointDetailsWellHistory = null

  showSetpointWellHistoryDetailsPopup = false

  chartLoaded = false;

  defermentLabelingModal = false;

  isLoadingSetpointData = false;

  showJobDetailsPopup = false;

  stepper = 1;

  selectionAction = '';

  stepOneVariant = '';

  datetime = '';

  selectionActions = [
    {
      text: 'Yes',
      value: 'YES',
    },
    {
      text: 'No',
      value: 'NO',
    },
    {
      text: 'Not sure',
      value: 'DONT_KNOW',
    },
  ];

  showComment = false;

  showActionComment = false;

  predictionAdditionalAction = '';

  setpointComment = '';

  comment = '';

  actionComment = '';

  standardReasons = getConfigEnv('FEEDBACK_REASONS');

  reason = [];

  stepTwoVariant = '';

  jobAction = [];

  jobActions = [
    'Changed setpoint',
    'On site fix',
    'Change setpoint',
    'Troubleshooting',
    'No action needed',
    'Other',
  ];

  stepThreeVariant = '';

  foundProcedure = null;

  activityShown = 'history';

  activeEvent = '';

  submittingSetpointData = false;

  get getUplift(): any {
    try {
      const rounded_value = setpointV2Module.uplift.toFixed(0);
      return rounded_value;
    } catch (err) {
      return setpointV2Module.uplift;
    }
  }

  get getDefermentUnits(): any {
    return setpointV2Module.unitType;
  }

  async acceptSetpointChanges() {
    this.comment = this.setpointComment;
    this.submittingSetpointData = true;
    this.selectionAction = 'YES';
    // @ts-ignore
    this.reason = ['Setpoint Accepted'];

    tasqFeedbackModule.leaveFeedback({
      tasq: this.tasq,
      selection: 'YES',
	  comment: this.comment,
	  markAsComplete: false,
	  shouldPostJobComment: this.comment != '',
	  shouldPostAsGlobalWellComment: true,
    });

    const setpointData = setpointV2Module.tasqSetpointData;
    setpointData.Uplift = `${this.getUplift} ${this.getDefermentUnits}`;
    tasqFeedbackModule.postComment({
      tasq: this.tasq,
      selection: 'YES',
	  setpointData,
    });

    const date = new Date();
    await tasqActionsModule.updateTasqStatus({
      reason: 'Setpoint accepted, waiting 7 days for results',
      date: date.setDate(date.getDate() + 7),
    });

    const successText = 'Setpoint accepted!';
    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    this.submittingSetpointData = false;
    if(tasqsListModule.activePage === 'Kanban'){
       this.$router.push({ name: 'TasqsKanban' });
        tasqsListModule.setKanbanSelectedTasq('')
    }else if(tasqsListModule.activePage === 'Operations'){
      this.$router.push({ name: 'Operations' });
      tasqsListModule.setKanbanSelectedTasq('')
    }else if(tasqsListModule.activePage === 'Workflow'){
      this.$router.push({ name: 'Workflow' });
      tasqsListModule.setKanbanSelectedTasq('')
    }else if(tasqsListModule.activePage === 'Map'){
      this.$router.push({ name: 'Map' });
       tasqsListModule.setKanbanSelectedTasq('')
    }else {
      this.$router.push({ name: 'Tasqs' });
    }
    this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  async dismissSetpointForNow() {
    let successText = 'Success'!;
    this.submittingSetpointData = true;
    const date = new Date();
    successText = 'Successfully added to waitlist!';
    await tasqActionsModule.updateTasqStatus({
      reason: 'Setpoint dismissed for 7 days',
      date: date.setDate(date.getDate() + 7),
    });

    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    this.submittingSetpointData = false;

    if(tasqsListModule.activePage === 'Kanban'){
       this.$router.push({ name: 'TasqsKanban' });
       tasqsListModule.setKanbanSelectedTasq('')
    }else if(tasqsListModule.activePage === 'Operations'){
      this.$router.push({ name: 'Operations' });
      tasqsListModule.setKanbanSelectedTasq('')
    }else if(tasqsListModule.activePage === 'Workflow'){
      this.$router.push({ name: 'Workflow' });
      tasqsListModule.setKanbanSelectedTasq('')
    }else if(tasqsListModule.activePage === 'Map'){
      this.$router.push({ name: 'Map' });
       tasqsListModule.setKanbanSelectedTasq('')
    }else {
      this.$router.push({ name: 'Tasqs' });
    }
    //   this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');

    this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  async doNotAcceptSetpointChanges() {
    this.comment = this.setpointComment;
    this.submittingSetpointData = true;
    this.selectionAction = 'NO';
    // @ts-ignore
    this.reason = ['Setpoint rejected'];
    // await this.leaveFeedback(true, true, true);

    tasqFeedbackModule.leaveFeedback({
      tasq: this.tasq,
      selection: 'NO',
	  comment: this.comment,
	  reason: this.reason,
	  correctedStateChangeDate: '',
	  jobAction: [],
	  markAsComplete: true,
	  shouldPostJobComment: this.comment != '',
	  shouldPostAsGlobalWellComment: true,
	  completedForToday: true,
    });
    // tasq: this.tasq,
    // selection: this.selectionAction,
    // // @ts-ignore
    // reason: this.reason,
    // correctedStateChangeDate: this.datetime || '',
    // comment: this.comment,
    // // procedure: this.foundProcedure,
    // jobAction: this.jobAction,
    // markAsComplete,
    // shouldPostJobComment,
    // completedForToday: completeForToday,

    const successText = 'Setpoint rejected';
    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    this.submittingSetpointData = false;
    if(tasqsListModule.activePage === 'Kanban'){
       tasqsListModule.setKanbanSelectedTasq('')
       this.$router.push({ name: 'TasqsKanban' });
    }else if(tasqsListModule.activePage === 'Operations'){
      this.$router.push({ name: 'Operations' });
       tasqsListModule.setKanbanSelectedTasq('')
    }else if(tasqsListModule.activePage === 'Workflow'){
      this.$router.push({ name: 'Workflow' });
      tasqsListModule.setKanbanSelectedTasq('')
    }else if(tasqsListModule.activePage === 'Map'){
      this.$router.push({ name: 'Map' });
       tasqsListModule.setKanbanSelectedTasq('')
    }else {
      this.$router.push({ name: 'Tasqs' });
    }
    this.$eventBus.$emit(SHOW_ALERT, successText);
    // this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  closeSetpointModal() {
	  this.showJobDetailsPopup = false;
  }

  chartData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: false,
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    annotation: {
      annotations: [
		  {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: '2021-08-21T13:35',
          borderColor: 'rgba(255,255,255,0.7)',
          borderDash: [9, 7],
          // label: {
          //   content: "TODAY",
          //   enabled: true,
          //   position: "top"
          // }
		  },
	  ],
    },
  }

  chartCurrentData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartCurrentOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'rgba(255,255,255,0.7)',
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    annotation: {
      annotations: [
	  ],
    },
  }

  chartBestData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartBestOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#FFFFFF',
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    annotation: {
      annotations: [
	  ],
    },
  }

  showSetpointDataFromHistory(setpointData) {
  }

  mainTitleValue() {
    // if (!this.tasq.completed) {
    return 'What action was taken?';
    // } else {
    // 	return "This tasq was completed"
    // }
  }

  get currentWellType(): any {
    if(assetsModule.activeTasq){
      return 'producing'
    }
	  return this.$route.query.type;
  }

  showSetpointAnalysisView() {
	  this.showJobDetailsPopup = true;
  }

  getWellCommentResponseIcon(comment) {
    if (comment.ResponseData.UserResponse.Selection == 'YES') {
      return 'task_alt';
    } if (comment.ResponseData.UserResponse.Selection == 'NO') {
      return 'arrow_downward';
    } if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
      return 'quiz';
    } if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
      return 'quiz';
    } if (comment.ResponseData.UserResponse.Selection == 'AUTO') {
      return 'smart_toy';
    }
  }

  getReadableWellHistoryComment(comment) {
    var readableComment = 'Confirmed';
    if (comment.ResponseData.UserResponse.Selection == 'YES') {
      var readableComment = 'Confirmed';
    } else if (comment.ResponseData.UserResponse.Selection == 'NO') {
      var readableComment = 'No';
    } else if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
      var readableComment = 'Not sure of';
    } else if (comment.ResponseData.UserResponse.Selection == 'AUTO') {
      var readableComment = 'Closed automatically';
    }

    if (comment.ResponseData.UserResponse.Selection != 'AUTO' && (comment.JobType.toLowerCase() == 'anomaly' || comment.JobType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'anomaly' || this.tasq.predictionType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'prolonged anomaly')) {
      readableComment += ' - Anomaly';
    } else if (comment.JobType.toLowerCase() == 'off-target' || this.tasq.predictionType.toLowerCase() == 'off-target') {
      readableComment += ' - Off-Target';
    } else if (comment.JobType.toLowerCase() == 'off-target rt' || this.tasq.predictionType.toLowerCase() == 'off-target rt') {
      readableComment += ' - Off-Target RT';
    } else if (comment.JobType.toLowerCase() == 'setpoint') {
      readableComment += ' - Setpoint';
    }

    if (comment.ResponseData.UserResponse.Selection != 'AUTO' && comment.ResponseData.UserResponse.SelectionDetails.Reason.length > 0) {
      readableComment += ': ';
      for (let x = 0; x < comment.ResponseData.UserResponse.SelectionDetails.Reason.length; x++) {
        readableComment = `${readableComment} ${comment.ResponseData.UserResponse.SelectionDetails.Reason[0]}`;
      }
    }
    return readableComment;
  }

  get tasq() {
	  if (tasqsListModule.isBatchResponding) {
      if(!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
		  return tasqsListModule.activeTasq as TasqJob;
	  }
    if (this.currentWellType == 'producing') {
      return assetsModule.activeTasq as TasqJob;
    }
    return tasqsListModule.activeTasq as TasqJob;
  }

  getFormattedDatetime() {
    if (!this.datetime) return '';
    return DateTime.fromISO(this.datetime).toFormat('hha, LLL d, y');
  }

  get tasqResponseData() {
    if (workflowModule.wellResponseData) {
      return workflowModule.wellResponseData;
    }
    return null;
  }

  get nextButtonTitle() {
    // if (this.tasq.isManuallyAdded) {
    //   return 'Finish';
    // }
    return 'Continue';
  }

  get identifyingSubText() {
    if (this.tasq.isManuallyAdded) {
      return `Has this ${this.tasq.predictionType} job been resolved?`;
    }
    return '';
  }

  get identifyingText() {
    // if (this.tasq.completed) return 'This tasq has been closed'
    if ((this.tasq || {}).noComms) return 'Has this been resolved?';
    if ((this.tasq || {}).wellTestData) return 'Has this well test been completed?';
    if (
      this.tasq.engineerType != null && this.tasq.engineerType === this.$getConst('TASQ_PROLONGED_ANOMALY_TYPE')
      && !this.tasqResponseData?.ResponseData?.UserResponse?.SelectionDetails?.Reason?.[0] // eslint-disable-line
    ) {
      return 'Is there a change in well behavior?';
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_WORKFLOW_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_PROLONGED_ANOMALY_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')
      || this.tasq.predictionType === 'Reject this'
    ) return 'What action was taken?';
    if ((this.tasqResponseData || {}).ResponseData) return 'Please update your feedback:';
    if (this.tasq.engineerType != null && this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')) {
      return 'Is there a change in well behavior?';
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
      return 'Is this well off target?';
    }
    if (this.tasq.isManuallyAdded) {
      return this.tasq.predictionType;
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_PREDICTION_TYPE')) {
      return 'What would you like to do?';
    }
    return this.tasq.engineerType === 'issue' || !this.tasq
      ? 'Is there an issue here?' : `Did Tasq correctly identify the ${this.tasq?.engineerType}?`;
  }

  get activeActionText() {
    let text = '';
    if (
      this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')
      || (this.tasq.engineerType === this.$getConst('TASQ_COMPLETED_TYPE') && this.tasq.predictionType === 'state change')
    ) {
      if (this.selectionAction === this.selectionActions[0].value) {
        text = 'Please select type of change:';
      }
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_FAILURE_TYPE')) {
      if (this.selectionAction === this.selectionActions[1].value) {
        text = 'Please select the reason:';
      }
    }
    if (this.tasq.engineerType === 'issue') {
      if (this.selectionAction === this.selectionActions[0].value) {
        text = 'Please select the reason:';
      }
    }
    return text;
  }

  get possibleReasons() {
    let reasons = this.standardReasons;
    if (
      this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE')
      || this.tasq.engineerType === 'issue'
      || (this.$getConst('TASQ_COMPLETED_TYPE') && this.tasq.predictionType === 'state change')
      || (this.$getConst('TASQ_OFF_TARGET_TYPE') && this.tasq.predictionType === this.$getConst('TASQ_OFF_TARGET_TYPE'))
    ) {
      reasons = this.standardReasons;
      if (this.tasq.predictionType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
        reasons.push('Data issue');
        reasons.push('Downhole solids');
      }
    }

    reasons = [...new Set(reasons)];
    reasons = reasons.sort();
    if (!reasons.includes('Other')) {
      reasons.push('Other');
    }
    return reasons;
  }

  get events() {
    return tasqsListModule.tasqEventHistoryList;
    // return tasqProductionDataChartModule.wellHistory;
  }

  get getWellHistory() {
    return workflowModule.wellHistory;
  }

  get isBatchResponding() {
	  return tasqsListModule.isBatchResponding
  }

  async created() {

	  if (tasqsListModule.isBatchResponding) {
		  tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0])
		  return tasqsListModule.activeTasq as TasqJob;
	  }

    // workflowModule.getWellHistory(this.tasq.wellName)
    this.$eventBus.$on(CHART_CLICK_EVENT, (date) => {
      if (this.stepper === 4) {
        this.datetime = date;
      }
    });


    if (this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')) {
      this.setupSetpointPage();
    }
    // set actionMapping
    this.actionMapping();
  }

  deleteSelectedReason(reasonValue: any = '') {
    const value = reasonValue;
    // @ts-ignore
    const index = this.reason.indexOf(value);
    if (index > -1) {
      // @ts-ignore
      this.reason.splice(index, 1);
    }
  }

  deleteSelectedAction(actionValue: any = '') {
    const value = actionValue;
    // @ts-ignore
    const index = this.jobAction.indexOf(value);
    if (index > -1) {
      // @ts-ignore
      this.jobAction.splice(index, 1);
    }
  }

  async setupSetpointPage() {
	  this.isLoadingSetpointData = true;
	  this.chartLoaded = false;
    await this.changeChartTime();

    const { last_7 } = setpointV2Module.tasqMCFData;
    const projected_3 = setpointV2Module.tasqMCFData.best_3;

    const lift_pressure_current = setpointV2Module.tasqListPressureData.Current;
    const lift_pressure_best = setpointV2Module.tasqListPressureData.Best;

    const trying: any[] = [];
    for (let x = 0; x < lift_pressure_current.length; x++) {
      const d = new Date();
 		d.setDate(d.getDate() - x);
      trying.push(d.toLocaleString());
    }

    this.chartCurrentData = {
      labels: trying,
      datasets: [{
        label: 'Current',
        // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
        data: lift_pressure_current,
        borderColor: '#2CE6C2',
        borderWidth: 1.5,
        pointBackgroundColor: '#0076FF',
        pointBorderColor: '#0076FF',
        pointRadius: 0,
        backgroundColor: 'rgba(44,230,194,0.1)',
        fill: true,
        showLine: true,
        tension: 0,
        spanGaps: true,
        pointHitRadius: 5,
        pointHoverRadius: 0,
      }, {
        label: 'Optimized',
        // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
        data: lift_pressure_best,
        borderColor: '#e66d2c',
        borderWidth: 1.5,
        pointBackgroundColor: '#0076FF',
        pointBorderColor: '#0076FF',
        pointRadius: 0,
        backgroundColor: 'rgba(230, 109, 44,0.1)',
        fill: true,
        showLine: true,
        tension: 0,
        spanGaps: true,
        pointHitRadius: 5,
        pointHoverRadius: 0,
      },
      ],
    };

    this.chartData = {
      labels: ['2021-08-21T13:05', '2021-08-21T13:10', '2021-08-21T13:15', '2021-08-21T13:20', '2021-08-21T13:25', '2021-08-21T13:30', '2021-08-21T13:35', '2021-08-21T13:40', '2021-08-21T13:45'],
      datasets: [{
        label: '',
        // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
        data: last_7,
        borderColor: '#2CE6C2',
        borderWidth: 1.5,
        pointBackgroundColor: '#0076FF',
        pointBorderColor: '#0076FF',
        pointRadius: 0,
        backgroundColor: 'rgba(44,230,194,0.1)',
        fill: true,
        showLine: true,
        tension: 0,
        spanGaps: true,
        pointHitRadius: 5,
        pointHoverRadius: 0,
      }, {
        label: '',
        // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
        data: [null, null, null, null, null, null, ...projected_3],
        borderColor: '#2CE6C2',
        borderWidth: 1.5,
        pointBackgroundColor: '#0076FF',
        pointBorderColor: '#0076FF',
        pointRadius: 0,
        backgroundColor: 'rgba(44,230,194,0.1)',
        fill: true,
        showLine: true,
        tension: 0,
        spanGaps: true,
        pointHitRadius: 5,
        pointHoverRadius: 0,
      },
      ],
    };

    this.chartLoaded = true;
    this.isLoadingSetpointData = false;
  }

  async changeChartTime(value = 14) {
	  await setpointV2Module.getSetpointRecV2ForTasq({ wellName: this.tasq.wellName });
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async resetDefermentPage() {
    this.defermentLabelingModal = false;
    await this.sleep(0.1);
    this.defermentLabelingModal = true;
  }

  handleStepOne(omitDefermentLabeling = false) {
	  if (this.tasq.isManuallyAdded) {
		  this.selectionAction = 'YES';
		  // this.leaveFeedback(true, true, true);
		  // return;
	  }
    if (!this.selectionAction) {
      this.stepOneVariant = 'error';
      return;
    }
    if (this.selectionAction === 'WAIT_AND_SEE') {
      // this.$store.dispatch(ADD_TO_WATCH_LIST, {
      //   tasq: this.tasq,
      // });
      this.$router.push({
        name: 'Tasqs',
      });
      return;
    }
    if (this.selectionAction === 'NO') {
      if (this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE') && !omitDefermentLabeling && !this.isBatchResponding) {
        // this.$dialog.show('defermentLabelingModal');
        this.defermentLabelingModal = true;
        return;
      }else {
        this.defermentLabelingModal = false;
      }
      this.leaveFeedback(true, true, true);
      return;
    }
    tasqFeedbackModule.leaveFeedback({
      tasq: this.tasq,
      selection: this.selectionAction,
    });
    this.stepper = 2;
    this.feedbackSteps[1].active = true;
  }

  handleStepTwo() {
    if (!this.reason.length) {
      this.stepTwoVariant = 'error';
      return;
    }
    if (this.predictionAdditionalAction === 'Failure did not occur') {
      this.selectionAction = this.selectionActions[1].value; // NO
      this.leaveFeedback(true, true);
      return;
    }
    if (this.predictionAdditionalAction === 'Validate failure now') {
      this.selectionAction = this.selectionActions[0].value; // YES
    }
    if (this.predictionAdditionalAction === 'Failure occurred previous to prediction') {
      this.selectionAction = this.selectionActions[1].value; // NO
    }
    tasqFeedbackModule.leaveFeedback({
      tasq: this.tasq,
      selection: this.selectionAction,
	  // @ts-ignore
      reason: this.reason,
      comment: this.comment,
	  shouldPostJobComment: true,
    });
    this.stepper = 3;
    this.feedbackSteps[2].active = true;
  }

  handleStepThree() {
    tasqFeedbackModule.leaveFeedback({
      tasq: this.tasq,
      selection: this.selectionAction,
	  // @ts-ignore
      reason: this.reason,
      correctedStateChangeDate: this.datetime || '',
      comment: this.comment,
      jobAction: this.jobAction,
      markAsComplete: true,
    });
    this.stepper = 4;
  }

  handleStepFour() {
    if (this.tasq.predictionType === this.$getConst('TASQ_FAILURE_PREDICTION_TYPE')) {
      this.stepper = 4;
      return;
    }
    this.leaveFeedback(true, true);
  }

  // eslint-disable-next-line consistent-return
  handlePossibleActionsChange(value) {
    if (this.tasq.predictionType === this.$getConst('TASQ_FAILURE_PREDICTION_TYPE')) {
      return value;
    }
    // this.getFoundProcedure();
  }

  handleStepClick(step) {
    if (!step.active) return;
    this.stepper = step.id;
  }

  handleTasqChange() {
    this.startDataLoading();
    this.resetFeedback();
    if (this.tasqResponseData && this.tasqResponseData.ResponseData) {
      const userSelection = this.tasqResponseData.ResponseData.UserResponse.Selection;
      const date = this.tasqResponseData.ResponseData.UserResponse.CorrectedStateChangeDate;
      this.selectionAction = userSelection;
      this.reason = this.tasqResponseData.ResponseData.UserResponse.SelectionDetails.Reason;
      this.datetime = date;
      this.jobAction = this.tasqResponseData.ResponseData.UserResponse.SelectionDetails.Action || [];
    }
    if (this.tasqResponseData && this.tasqResponseData.stepOneCompleted) {
      this.feedbackSteps[1].active = true;
      this.stepper = 2;
    }
    if (this.tasqResponseData && this.tasqResponseData.stepTwoCompleted) {
      this.feedbackSteps[2].active = true;
      this.stepper = 3;
    }
    if (this.tasqResponseData && this.tasqResponseData.stepThreeCompleted) {
      if (this.tasq.completed || this.tasq.predictionType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
        this.stepper = 3;
      } else if (this.feedbackSteps[3]) {
        this.feedbackSteps[3].active = true;
        this.stepper = 4;
      }
    }
    if (this.tasqResponseData && this.tasqResponseData.stepFourCompleted) {
      this.stepper = 4;
    }

    if (this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')) {
      this.selectionActions = [{
        text: 'Updated setpoint',
        value: 'YES',
      }, {
        text: 'Not now',
        value: 'NO',
      }];
    } else if (this.tasq.engineerType === this.$getConst('TASQ_WELL_TEST_TYPE')) {
      this.selectionActions = [
        {
          text: 'Yes',
          value: 'YES',
        },
        {
          text: 'No',
          value: 'NO',
        },
      ];
    } else if (this.tasq.isManuallyAdded) {
      this.selectionActions = [];
    } else if (this.tasq.completed) {
      this.selectionActions = [];
    } else {
      this.selectionActions = [
        {
          text: 'Yes',
          value: 'YES',
        },
        {
          text: 'No',
          value: 'NO',
        },
        {
          text: 'Not sure',
          value: 'DONT_KNOW',
        },
      ];
    }
    this.stopDataLoading();
  }

  resetFeedback() {
    this.stepper = 1;
    this.selectionAction = '';
    this.datetime = '';
    this.jobAction = [];
    this.comment = '';
    this.reason = [];
    this.showComment = false;
    this.showActionComment = false;
  }

  @Debounce(1000)
  async leaveFeedback(
    skipDateTime = false,
    markAsComplete = false,
    shouldPostJobComment = false,
    completeForToday = false,
  ) {
    // @ts-ignore
    if (this.jobAction.find((j) => j.includes('Reassign'))) {
      // eslint-disable-next-line no-param-reassign
      markAsComplete = false;
      this.$emit('reassign-tasq');
    }
    if (this.datetime || skipDateTime) {
      this.startDataLoading();
      // this.actionSelected = true;
      await tasqFeedbackModule.leaveFeedback({
        tasq: this.tasq,
        selection: this.selectionAction,
        // @ts-ignore
        reason: this.reason,
        correctedStateChangeDate: this.datetime || '',
        comment: this.comment,
        // procedure: this.foundProcedure,
        jobAction: this.jobAction,
        actionComment: this.actionComment,
        markAsComplete,
        shouldPostJobComment,
        completedForToday: completeForToday,
      });
      // @ts-ignore
       if (!this.jobAction.find((j) => j.includes('Reassign'))) {
        if(tasqsListModule.activePage === 'Kanban'){
          tasqsListModule.setKanbanSelectedTasq('')
          this.$router.push({ name: 'TasqsKanban' });
        }else if(tasqsListModule.activePage === 'Operations'){
          tasqsListModule.setKanbanSelectedTasq('')
          this.$router.push({ name: 'Operations' });
        }else if(tasqsListModule.activePage === 'Workflow'){
          this.$router.push({ name: 'Workflow' });
          tasqsListModule.setKanbanSelectedTasq('')
        }else if(tasqsListModule.activePage === 'Map'){
          this.$router.push({ name: 'Map' });
          tasqsListModule.setKanbanSelectedTasq('')
        }
        else {
          this.$router.push({ name: 'Tasqs' });
          this.closeBatchResponseFeedbackSidebar()
        }
         await tasqsListModule.getTasqs({ val: tasqsListModule.activeListType });
       }
        this.stopDataLoading();
      this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');
    }
  }

  toggleShowActivity(activity) {
    this.activityShown = activity;
  }

  showEventOnChart(event) {
    return event;
  }

  openStepDetails(step) {
    (this.foundProcedure as any).steps.forEach((i) => {
      if (step.id !== i.id) {
        Object.assign(i, { showDetails: false });
      }
    });
    Object.assign(step, { showDetails: !step.showDetails });
  }

  updateTasqTime() {
    this.$dialog.show('productionDataModal');
  }

  @Watch('tasq.id', {
    immediate: true,
  })
  onTasqChange() {
    this.handleTasqChange();
  }

  @Watch('jobAction')
  onJobActionChange(value){
    if(value.find((j) => j.includes('Other'))){
      this.showActionComment = true;
    }
  }

  @Watch('showComment')
  onShowComment(value) {
    if (value) {
      this.sleep(300).then(() => {
        const scrollView = document.getElementById('tasqFeedbackScrollView')!;
        scrollView.scrollTo(0, scrollView.scrollHeight);
      });
    }
  }

  @Watch('showActionComment')
  onShowActionComment(value) {
    if (value) {
      this.sleep(300).then(() => {
        const scrollView = document.getElementById('tasqFeedbackScrollView')!;
        scrollView.scrollTo(0, scrollView.scrollHeight);
      });
    }
  }

  @Watch('tasqResponseData', {
    immediate: true,
  })
  onTasqResponseChange() {
    this.handleTasqChange();
  }
}
